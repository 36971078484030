<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content" v-if="!viLoader">
      <!-- <div class="dispflex">
        <h1 class="hdrtitle" style="margin-top: 0px;">School Hub</h1>
         <button type="button" class="backbtn" @click="previousRoute">
                <i class="fa fa-arrow-left"></i>
              </button>
      </div> -->
      <div class="webNew-dispflex"   >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        School Hub
       </h4>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="widjethdr dispflex">
              <div style="width: auto">
                <span class="iconsect blubg" style="padding-left: 11px"
                  ><i class="fas fa-user-graduate"></i
                ></span>
                <h1>Manage Students</h1>
              </div>
              <span class="has-float-label" style="width: 14%;margin-left: 11px;">
                   <select
										class="form-control form-input"
										id="deptlistid"
										@change="getSelectRange"
										v-model="selectAcademicYear"
										>
										<option :value=null>-- Select --</option>
										<option v-for="(item,index) in academicYearList" :key="index" :value="item._id">
											{{ item.from}} - {{ item.to }}
										</option>
										</select>
                    <label for="clslistid">Select Academic Year </label>
              </span>
              <span class="has-float-label">
                    <select
                          class="form-control form-input"
                          id="deptlistid"
                          v-model="selectClass"
                          @change="selectClassSetion"
                        >
                          <option :value="null">-- Select --</option>
                          <option
                            v-for="(item, index) in classList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.className }} 
                          </option>
                        </select>
                    <label for="clslistid">Select Class </label>
              </span>
             
           
               <span class="has-float-label">
                   <select
										class="form-control form-input"
										id="deptlistid"
										@change="getSelectStatus"
										v-model="selectedStatus"
										>
										<option :value=null>-- Select --</option>
										<option v-for="(item,index) in statusList" :key="index" :value="item">
											{{ item }}
										</option>
										</select>
                    <label for="clslistid">Select Status </label>
              </span>
              <div class="nottble" style="margin-top:8px;">
               <div class=" chkbox" data-title="Select">
                  <label class="custcheckbox">
                    <input type="checkbox" @change="getSelectIsSibiling" v-model="selectedSibiling" />
                    <span class="checkmarkchk"></span>
                    
                  </label>
                  <label style="margin-left:35px">Show Siblings</label>
                </div>
                
              </div>
              
              <div style="margin-top:10px" class="flexitm">
                 
                <input type="text" v-model="searchWords" placeholder="Search Students">
              </div>
              <div class="mt-1">
                
                <button class="btn gry_bg tooltipt mpopup twoicn" @click.prevent="openStudentPoup">
                  <i class="fas fa-user-graduate user1"></i>
                  <p class="user2"><i class="fas fa-plus"></i></p>
                  <span class="tooltiptext">Add Student</span>
                </button>
              </div>
            </div>
            <div class="widjetcontent">
              <div class="countsect p-2 mt-3 mb-3">
							<ul class="dispflex">
								<li><span class="badge contbadge">{{ totalCount.length  }}</span>Total Students</li>
								<li><span class="badge contbadge">{{ totalCount.length > 0 ? getActiveStudents : 0 }}</span>Active Students</li>
								<li><span class="badge contbadge">{{ totalCount.length > 0 ? getInActiveStudents : 0 }}</span>Inactive Students</li>
							</ul>
						</div>
              <div >
                <div class="restable">
                  <div class="resrow resheader">
                    <div class="cell">Student Name</div>
                     <div class="cell">Roll No</div>
                     <div class="cell">Academic Year</div>
                    
                    <div class="cell">Date Of Joining</div>
                    <div class="cell">Class</div>
                    <div class="cell">Classroom</div>
                     <div class="cell">Status</div>
                    
                     
                    <div class="cell">Action</div>
                  </div>
                  <div
                    class="resrow"
                    v-for="(student, index) in studentList"
                    :key="index"
                  >
                  
                    <div class="cell" data-title="Student Name">
                      <a style="padding-left: 6px;" href="javascript:void(0);"  @click.prevent="redirectRoute(`/admission/student/view/details/${student._id}`)">{{ student.firstName + " " + student.lastName }} </a>
                    </div>
                    <div class="cell" data-title="Student Name">
                     {{ student.rollNoAllocated }}
                    </div>
                    
                    <div class="cell" data-title="Student Name">
                     {{ getAcademicYearName(student.academicYear) }}
                    </div>
                    <div class="cell" data-title="Parent Name">
                      {{ student.doj | dateFormat }}
                    </div>
                    <div class="cell" data-title="Class">
                      {{ student.class ? getClassName(student.class) : '' }}
                    </div>
                    <div class="cell" data-title="Class">
                      {{ student.classSection ? getSectionName(student.classSection) : '' }}
                    </div>
                    <div class="cell" data-title="Class">
                      {{ student.admissionStatus }}
                    </div>
                    
                     
                    <div class="cell" data-title="Action" >
                        <button class="btn round tooltipt"  :disabled="student.parent && student.parent.isPasswordVerified" style="background-color: darkcyan;" @click.prevent="inviteParent(index)">
                        <i class="fas fa-chalkboard-teacher" style="padding-left:4px;"></i>
                        <span v-if="student.parent && !student.parent.isPasswordVerified" class="tooltiptext">Invite Parent</span>
                        <span v-else class="tooltiptext">Invited</span>
                      </button>
                      <button class="btn round orange tooltipt" @click.prevent="editStudentModal(index)">
                        <i class="fas fa-edit"></i><span class="tooltiptext">Edit</span>
                      </button>
                        <button class="btn round red tooltipt" @click="showDeleteModal(student._id,student.parent._id)"  >
                          <i class="far fa-trash-alt"></i> <span class="tooltiptext">Delete</span>
                        </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center mt-4" v-if="studentList.length > 0">
                <div class="pagination" id="pagination">
                  <a  :style="currentPageNumber === 1 ? 'pointer-events: none;' : ''" @click="goToPreviousPage">&laquo;</a>
                  <a
                    v-for="(item, index) in lastPageNumber"
                    :key="index"
                    v-on:click="selected = item"
                    v-bind:class="{ active: selected == item }"
                    @click="navToSelectedPage(item)"
                    >{{ item }}</a
                  >
                  <a  :style="currentPageNumber === lastPageNumber ? 'pointer-events: none;' : ''" @click="goToNextPage">&raquo;</a>
                </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>

   <div>
    <b-modal id="addstudpop" class="modal" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closePoup">×</button>
      <div class="contact-form">
        <h2>Add Student</h2>
        <br />
        <div style="height: 580px; overflow-y: auto">
          <form data-vv-scope="studentvalidate" id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    v-validate="'required'"
                    name="studentFirstName"
                    v-model="contactDetails.firstName"
                    class="form-control form-input"
                    id="stdFirstName"
                    placeholder="First Name"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentFirstName')"
                    class="vi-error"
                    style="top:-1em"
                    >First name is required</label
                  >
                  <label for="stdFirstName" v-else
                    >First Name <span class="required">*</span></label
                  >
                </span>
              </div>
               <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    name="studentFirstName"
                    v-model="contactDetails.middleName"
                    class="form-control form-input"
                    id="stdFirstName"
                    placeholder="Middle Name"
                  />
                 
                  <label for="stdFirstName"
                    >Middle Name </label
                  >
                </span>
              </div>
               <div class="form-group input-group col-md-4 mt-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="text"
                    name="studentLastName"
                    v-model="contactDetails.lastName"
                    class="form-control form-input"
                    id="stdLastName"
                    placeholder="Last Name"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentLastName')"
                    class="vi-error"
                    style="top:-1em"
                    >Last name is required</label
                  >
                  <label for="stdLastName" v-else
                    >Last Name<span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <input
                          @input="checkROllNo"
                          v-validate="'required'"
                          type="text"
                          name="roll No allocated"
                          v-model="contactDetails.rollNoAllocated"
                          class="form-control form-input"
                          id="rnaltid"
                          placeholder="Roll No. allocated"
                        />

                         <label
                            v-if="errors.first('studentvalidate.roll No allocated') || !isRollNoAvailable"
                            class="vi-error"
                            style="top:-1em"
                             > {{ errors.first('studentvalidate.roll No allocated') ? 'Roll no allocated is required' : 'Roll No already exists. Please use different one.' }} </label
                          >

                        <label for="rnaltid" v-else
                          >Roll No. allocated 
                          <span class="required">*</span>[Prefix Roll No. in <a style="padding-left: 6px;" href="https://dev.vidhyaan.com/admission/admissionSettings" target="_blank" >Setting </a>]</label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label" style="margin-right: 0%;">
                          <input
                            v-validate="'required'"
                            @input="checkEmail"
                            name="urname"
                            v-model="userDetails.userName"
                            class="form-control form-input"
                            id="ptemailid"
                            placeholder="Please enter unique username"
                            
                          />                
                          <label
                            v-if="errors.first('studentvalidate.urname') || !isEmailIdAvailable"
                            class="vi-error"
                            style="top:-1em"
                             > {{ errors.first('studentvalidate.urname') ? 'User Name is required' : 'User Name already exists. Please use different one.' }} </label
                          >
                      
                          <label for="ptemailid" v-else
                            >UserName <span class="required">*</span></label
                          >
                        </span> 
                        <span  style="padding-top: 3%;"> {{ schoolNameAppendValue }}  </span>
                      </div>

                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            v-validate="'required'"
                            type="email"
                            :disabled="editId && userDetails.isPasswordVerified"
                            name="ptemail"
                            v-model="userDetails.email"
                            class="form-control form-input"
                            id="ptemailid"
                            placeholder="Email"
                            
                          />
                           <a v-if="userDetails.isPasswordVerified" href="javascript:void(0);" style="color: red;" @click="resetMailId(editId)">Change Primary Email
                         </a>
                         
                        
                          <label
                            v-if="errors.first('studentvalidate.ptemail')"
                            class="vi-error"
                            style="top:-1em"
                             > Primary Email Id is required </label
                          >
                      
                          <label for="ptemailid" v-else
                            >Primary Email <span class="required">*</span></label
                          >
                        </span> 
                      </div>
                      <div class="form-group input-group col-md-4 mt-3 pr-md-3" v-if="editId" >
                        <span class="has-float-label" style="box-shadow: 0 1px 0 0 #e5e5e5;">
                         {{ userDetails.isPasswordVerified ? 'Verified' : 'Not Verified' }}
                          <label for="stadrid" 
                            >Parent mail verification status</label
                          >
                        </span>
                      </div>

              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    v-validate="'required'"
                    type="date"
                    :max="minDob"
                    name="DOB"
                    v-model="contactDetails.dob"
                    class="form-control form-input"
                    id="stdDob"
                    placeholder="Date of Birth"
                  />
                  <label
                    v-if="errors.first('studentvalidate.DOB')"
                    class="vi-error"
                    style="top:-1em"
                    >Date of birth is required</label
                  >
                 
                 
                  <label for="stdDob" v-else
                    >Date of Birth <span class="required">*</span></label
                  >
                </span>
              </div>
               <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <input
                          v-validate="'required'"
                          type="date"
                          :min="contactDetails.dob"
                          :disabled="!contactDetails.dob"
                          onkeydown="return false"
                          name="DOJ"
                          v-model="contactDetails.doj"
                          class="form-control form-input"
                          id="DOJ"
                          placeholder="Date of Joining"
                        />
                        <label
                          v-if="errors.first('studentvalidate.DOJ')"
                          class="vi-error"
                          style="top:-1em"
                          >Date of Joining is required</label
                        >
                      
                      
                        <label for="DOJ" v-else
                          >Date of Joining <span class="required">*</span></label
                        >
                      </span>
                    </div>
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3" v-if="editId">
                      <span class="has-float-label">
                        <input
                        
                         v-validate="contactDetails.admissionStatus == 'Inactive' ? 'required' : false"
                          type="date"
                          :disabled="!contactDetails.doj"
                          :min="contactDetails.doj"
                          onkeydown="return false"
                          name="Leaving"
                          v-model="contactDetails.dateOfLeaving"
                          class="form-control form-input"
                          id="Leaving"
                          placeholder="Date of Leaving"
                        />
                        <label
                          v-if="errors.first('studentvalidate.Leaving')"
                          class="vi-error"
                          style="top:-1em"
                          >Date of Leaving is required</label
                        >
                        <label for="DOJ" v-else
                          >Date of Leaving <span class="required" v-if="contactDetails.admissionStatus == 'Inactive'">*</span></label
                        >
                      </span>
                    </div>
             
              
             
             
              <div class="form-group input-group col-md-2 pr-md-3">
                <div style="border-bottom: 1px solid #e5e5e5; width: 107%">
                  <label
                    style="color: #6c757d; margin-bottom: 0.2rem"
                    class="mr-3"
                    >Student Discount?</label
                  >
                  <label class="switch" style="margin-bottom: 0rem">
                    <input
                      type="checkbox"
                      id="sibtogBtn"
                      v-model="contactDetails.isStudentDiscount"
                    />
                    <div class="slider"></div>
                  </label>
                </div>
              </div>
              <div class="form-group input-group col-md-2 mt-3" >
                <span class="has-float-label" v-if="contactDetails.isStudentDiscount">
                  <input
                  v-validate="'numeric|min:0|max_value:100'"
                    type="number"
                    :disabled="!contactDetails.isStudentDiscount"
                    name="studentDiscountValue"
                    v-model="contactDetails.studentDiscountValue"
                    class="form-control form-input"
                    id="stdLastName"
                  />
                  <label
                    v-if="errors.first('studentvalidate.studentDiscountValue')"
                    class="vi-error"
                    style="top:-1em"
                    >Student Discount is required</label
                  >
                  <label for="stdLastName" v-else
                    >Student Discount(%)<span class="required">*</span></label
                  >
                </span>
              </div>
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <div style="border-bottom: 1px solid #e5e5e5; width: 100%">
                  <label
                    style="color: #6c757d; margin-bottom: 0.2rem"
                    class="mr-3"
                    >Siblings?</label
                  >
                  <label class="switch" style="margin-bottom: 0rem">
                    <input
                      type="checkbox"
                      id="sibtogBtn"
                      v-model="contactDetails.siblings"
                    />
                    <div class="slider"></div>
                  </label>
                </div>
              </div>
 
              <fieldset class="accordion-group-container">
                  <div >
                 
                  <label v-if="contactDetails.siblings"
                    style="margin: 0"
                    for="accordiongroup-3"
                    class="blutitle accordion-label"
                  >
                    SIBLINGS DETAILS
                  </label>
                  <div v-if="contactDetails.siblings" class="form-row">
                    <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                      <span class="has-float-label">
                        <v-select
                          v-validate="'required'"
                          name="sblgname"
                          v-model="contactDetails.siblingName"
                          class="form-control"
                          id="sblgname"
                          @input="setSblingRollNo(contactDetails.siblingName)"

                          placeholder="Type Sibling Name"
                          label="label"
                          :options="optionStaffList"
                         
                          :reduce="(label) => label.value"
                          
                        >
                          <span
                            v-if="!showOption"
                            style="display: none !important"
                            slot="no-options"
                          ></span>
                        </v-select>

                        <!-- <select
                          v-validate="'required'"
                          name="sblgname"
                          v-model="contactDetails.siblingName"
                          class="form-control form-input"
                          id="sblgname"
                          @change="setSblingRollNo(contactDetails.siblingName)"
                          placeholder="Student Name"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in auvaiableStudentList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.firstName }}  
                          </option>
                        </select> -->
                        <label
                            v-if="errors.first('studentvalidate.sblgname')"
                            class="vi-error"
                            style="top:-1em"
                            >Sibling Name is required</label
                          >
                        <!-- <label v-else for="sblgname"
                          >Sibling Name </label
                        > -->
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3">
                      <span class="has-float-label">
                        <input
                          type="text"
                          name="rollno"
                          disabled
                          v-model="contactDetails.siblingRollNo"
                          class="form-control form-input"
                          id="rollno"
                        />
                        <label for="rollno"
                          >Roll No</label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3">
                      <span class="has-float-label">
                        <input
                          type="text"
                          name="rollno"
                          disabled
                          v-model="contactDetails.siblingUserId"
                          class="form-control form-input"
                          id="rollno"
                        />
                        <label for="rollno"
                          >UserId</label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3">
                      <span class="has-float-label">
                        <input
                          type="number"
                          name="discount"
                          v-model="contactDetails.siblingDiscount"
                          class="form-control form-input"
                          id="rollsssno"
                          v-validate="'numeric|min:0|max_value:100'"
                        />
                        <label
                            v-if="errors.first('studentvalidate.discount')"
                            class="vi-error"
                            style="top:-1em"
                            >Sibling Discount (max 100%)</label
                          >
                        <label v-else for="rollsssno"
                          >Sibling Discount(%)</label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3">
                      <span class="has-float-label">
                        <input
                         disabled
                          name="discount"
                          v-model="contactDetails.siblingClass"
                          class="form-control form-input"
                          id="rollsssno"
                        />
                        
                        <label for="rollsssno"
                          >Class</label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-4 mt-3">
                      <span class="has-float-label">
                        <input
                          disabled
                          name="discount"
                          v-model="contactDetails.siblingSection"
                          class="form-control form-input"
                          id="rollsssno"
                        />
                       
                        <label for="rollsssno"
                          >Section</label
                        >
                      </span>
                    </div>
                    
                  </div>
                </div>
               

                <div>
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-2"
                    class="blutitle accordion-label"
                  >
                    CLASS SECTION DETAILS
                  </label>
                  <div class="form-row">
                   <div class="form-group input-group col-md-3 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <select
                          v-validate="'required'"
                          name="AcademicYear"
                          class="form-control form-input"
                          id="deptlistid"
                          v-model="contactDetails.branchId"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in getBranchList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                        <label
                    v-if="errors.first('studentvalidate.AcademicYear')"
                    class="vi-error"
                    style="top:-1em"
                    >Branch is required</label
                  >
                 
                  
                        <label for="acyearid" v-else
                          >Branch <span class="required">*</span></label
                        >
                      </span>
                    </div>
                    <div class="form-group input-group col-md-3 mt-3 pr-md-3">
                      <span class="has-float-label">
                        <select
                          @change="setPlanDate(contactDetails.academicYear)"
                          v-validate="'required'"
                          name="AcademicYear"
                          :disabled="!allowEditAcademicYear"
                          class="form-control form-input"
                          id="deptlistid"
                          v-model="contactDetails.academicYear"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in academicYearList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.from }} - {{ item.to }}
                          </option>
                        </select>
                        <label
                    v-if="errors.first('studentvalidate.AcademicYear')"
                    class="vi-error"
                    style="top:-1em"
                    >Academic year is required</label
                  >
                 
                  
                        <label for="acyearid" v-else
                          >Academic Year <span class="required">*</span></label
                        >
                      </span>
                    </div>

                    <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                      <span class="has-float-label">
                       
                         <select
                          v-validate="'required'"
                          name="Class"
                          class="form-control form-input"
                          id="deptlistid"
                           :disabled="!allowEditAcademicYear"
                          @change="getSectionList(contactDetails.class)"
                          v-model="contactDetails.class"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in classList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.className }}  
                          </option>
                        </select>
                        <label
                    v-if="errors.first('studentvalidate.Class')"
                    class="vi-error"
                    style="top:-1em"
                    >Class name is required</label
                  >
                 
                 
                        <label for="clname" v-else
                          >Class Name<span class="required">*</span></label
                        >
                      </span>
                    </div>
                     <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                      <span class="has-float-label">
                       
                         <select
                          v-validate="'required'"
                          name="Section"
                          class="form-control form-input"
                          id="deptlistid"
                          :disabled="!contactDetails.class"
                          v-model="contactDetails.classSection"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in sectionList"
                            :key="index"
                            :value="item._id"
                          >
                            {{ item.name }}  
                          </option>
                        </select>
                        <label
                            v-if="errors.first('studentvalidate.Section')"
                            class="vi-error"
                            style="top:-1em"
                            >Section is required</label
                          >
                 
                 
                        <label for="clname" v-else
                          >Section<span class="required">*</span></label
                        >
                      </span>
                    </div>
                     
                  </div>
                </div>

                 <div >
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-1"
                    class="blutitle accordion-label"
                  >
                    PARENT DETAILS
                  </label>
                    <div class="form-row">
                       
                       <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            v-validate="'required'"
                            name="pointOfContact"
                            class="form-control form-input"
                            id="pointOfContact"
                            v-model="contactDetails.pointOfContact"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in pointOfList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                          <label
                            v-if="errors.first('studentvalidate.pointOfContact')"
                            class="vi-error"
                            style="top:-1em"
                            >Point of contact is required</label
                          > 
                          <label for="firstName" v-else
                            >Point of contact <span class="required">*</span></label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-6 pr-md-3 mt-3">
                     </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            name="fthrname"
                            v-validate="contactDetails.pointOfContact == 'Father' ? 'required' : false"
                            v-model="contactDetails.fatherFirstName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Father First Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.fthrname')"
                            class="vi-error"
                            style="top:-1em"
                            >Father First Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Father First Name <span v-if="contactDetails.pointOfContact == 'Father'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            name="fthrlastname"
                            v-validate="contactDetails.pointOfContact == 'Father' ? 'required' : false"
                            v-model="contactDetails.fatherLastName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Father Last Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.fthrlastname')"
                            class="vi-error"
                            style="top:-1em"
                            >Father Last Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Father Last Name <span v-if="contactDetails.pointOfContact == 'Father'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                     
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="number"
                            onKeyPress="if(this.value.length==10) return false;" 
                            @input="checkMobile"
                            v-validate="contactDetails.pointOfContact == 'Father' ? 'required|numeric|min:10|max:10' : 'numeric|min:10|max:10'"
                            name="fphno"
                            v-model="contactDetails.mobileOne"
                            class="form-control form-input"
                            id="ptphid"
                            placeholder="Mobile"
                          />
                         
                          <label
                            v-if="errors.first('studentvalidate.fphno') || !isMobileAvailable"
                            class="vi-error"
                            style="top:-1em"
                            > {{ errors.first('studentvalidate.fphno') ? 'Please enter a valid mobile number(10 digits)' : 'Mobile Number already exists in this or another Vidhyaan School Account. It must be unique across all Accounts.' }}</label
                          >
                          <label v-else for="ptphid">Mobile <span v-if="contactDetails.pointOfContact == 'Father'" class="required">*</span></label>
                        </span>
                      </div>
                       <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            name="fthrname"
                            v-model="contactDetails.fatherOccuption"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Occupation"
                          />
                          
                          <label for="fthrnameid" 
                            >Occupation </label
                          >
                        </span>
                        
                      </div>
                       <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            name="mfirstName"
                            v-validate="contactDetails.pointOfContact == 'Mother' ? 'required' : false"
                            v-model="contactDetails.motherFirstName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Mother First Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.mfirstName')"
                            class="vi-error"
                            style="top:-1em"
                            >Mother First Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Mother First Name <span v-if="contactDetails.pointOfContact == 'Mother'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            name="mlastNmae"
                            v-validate="contactDetails.pointOfContact == 'Mother' ? 'required' : false"
                            v-model="contactDetails.motherLastName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Mother Last Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.mlastNmae')"
                            class="vi-error"
                            style="top:-1em"
                            >Mother Last Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Mother Last Name <span v-if="contactDetails.pointOfContact == 'Mother'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                      
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="number"
                            onKeyPress="if(this.value.length==10) return false;" 
                            @input="checkMobile"
                            v-validate="contactDetails.pointOfContact == 'Mother' ? 'required|numeric|min:10|max:10' : 'numeric|min:10|max:10'"
                            name="mphno"
                            v-model="contactDetails.mobileTwo"
                            class="form-control form-input"
                            id="ptphid"
                            placeholder="Mobile"
                          />
                         
                          <label
                            v-if="errors.first('studentvalidate.mphno') || !isMobileAvailable"
                            class="vi-error"
                            style="top:-1em"
                            > {{ errors.first('studentvalidate.mphno') ? 'Please enter a valid mobile number(10 digits)' : 'Mobile Number already exists in this or another Vidhyaan School Account. It must be unique across all Accounts.' }}</label
                          >
                          <label v-else for="ptphid">Mobile <span v-if="contactDetails.pointOfContact == 'Mother'" class="required">*</span></label>
                        </span>
                      </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            v-model="contactDetails.motherOccuption"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Occupation"
                          />
                          
                          <label for="fthrnameid" 
                            >Occupation</label
                          >
                        </span>
                        
                      </div>
                       <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            name="gfirstName"
                            v-validate="contactDetails.pointOfContact == 'Guardian' ? 'required' : false"
                            v-model="contactDetails.guardianFirstName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Guardian First Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.gfirstName')"
                            class="vi-error"
                            style="top:-1em"
                            >Guardian First Name is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Guardian First Name <span v-if="contactDetails.pointOfContact == 'Guardian'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            name="glastname"
                            v-validate="contactDetails.pointOfContact == 'Guardian' ? 'required' : false"
                            v-model="contactDetails.guardianLastName"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Guardian Last Name"
                          />
                          <label
                            v-if="errors.first('studentvalidate.glastname')"
                            class="vi-error"
                            style="top:-1em"
                            >Guardian Last is required</label
                          >
                          <label for="fthrnameid" v-else
                            >Guardian Last Name <span v-if="contactDetails.pointOfContact == 'Guardian'" class="required">*</span></label
                          >
                        </span>
                        
                      </div>
                     
                      <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="number"
                            onKeyPress="if(this.value.length==10) return false;" 
                            @input="checkMobile"
                            v-validate="contactDetails.pointOfContact == 'Guardian' ? 'required|numeric|min:10|max:10' : 'numeric|min:10|max:10'"
                            name="gphno"
                            v-model="contactDetails.mobileThree"
                            class="form-control form-input"
                            id="ptphid"
                            placeholder="Phone"
                          />
                         
                          <label
                            v-if="errors.first('studentvalidate.gphno') || !isMobileAvailable"
                            class="vi-error"
                            style="top:-1em"
                            > {{ errors.first('studentvalidate.gphno') ? 'Please enter a valid mobile number(10 digits)' : 'Mobile Number already exists in this or another Vidhyaan School Account. It must be unique across all Accounts.' }}</label
                          >
                          <label v-else for="ptphid">Mobile <span v-if="contactDetails.pointOfContact == 'Guardian'" class="required">*</span></label>
                        </span>
                      </div>
                       <div class="form-group input-group col-md-3 pr-md-3 mt-3">
                        <span class="has-float-label" >
                          <input
                            type="text"
                            v-model="contactDetails.guardianOccuption"
                            class="form-control form-input"
                            id="fthrnameid"
                            placeholder="Occupation"
                          />
                         
                          <label for="fthrnameid" 
                            >Occupation </label
                          >
                        </span>
                        
                      </div>
                      
              
                      
                      
                    </div>
                </div>
                  <div>
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-2"
                    class="blutitle accordion-label"
                  >
                    ADDRESS DETAILS
                  </label>
                  <div class="form-row">
                     <span style="width: 100%;" class="pl-1 my-2">
                                  <strong>Current Address</strong> &nbsp;
                                  &nbsp;

                                 
                                </span>
                    <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.addressOne"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Address Line 1"
                          />
                          <label for="occid"
                            >Address Line 1 </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.addressTwo"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Address Line 2"
                          />
                          <label for="occid"
                            >Address Line 2 </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.country"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Country"
                          />
                          <label for="occid"
                            >Country</label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <select
                            name="gendddfddder"
                            class="form-control form-input"
                            id="gedfdnder"
                            v-model="contactDetails.state"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in stateList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>

                         
                          <label for="occid"
                            >State </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.district"
                            class="form-control form-input"
                            id="occid"
                            placeholder="City/Town"
                          />
                          <label for="occid"
                            >City/Town</label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.postalCode"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Pin Code"
                          />
                          <label for="occid"
                            >Pin Code</label
                          >
                        </span>
                      </div>
                      <!-- <span style="width: 100%;" class="pl-1 my-2">
                                 

                                  <span style="color: #6c757d" class="ml-3"
                                    >Permanent address same as current address ?
                                    </span
                                  >
                                  <input
                                    @change="setPermentAddress(contactDetails.isPermentAddress)" v-model="contactDetails.isPermentAddress"
                                    type="checkbox"
                                  />
                                </span>
                      -->
                    
                  </div>
                  <div class="form-row" >
                  <div
                            class="form-group input-group col-md-6 mt-3"
                            
                          >
                              <!-- Permanent address same as current address ? -->
                             
                              <span >  Permanent address same as current address ?</span>

                               <div class="nottble" style="margin-top:0px;">
                                <div class=" chkbox" data-title="Select">
                                    <label class="custcheckbox">
                                      <input  type="checkbox" @change="setPermentAddress(contactDetails.isPermentAddress)" v-model="contactDetails.isPermentAddress" />
                                      <span class="checkmarkchk"></span>
                                    </label>
                                  </div>
                                  
                                </div>

                                 
                            
                          </div>
                           </div>
                  <div class="form-row" v-if="!contactDetails.isPermentAddress">
                     <span style="width: 100%;" class="pl-1 my-2">
                                  <strong>Permanent Address</strong> &nbsp;
                                  &nbsp;

                                 
                                </span>
                    <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.permentaddressOne"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Address Line 1"
                          />
                          <label for="occid"
                            >Address Line 1 </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.permentaddressTwo"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Address Line 2"
                          />
                          <label for="occid"
                            >Address Line 2 </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.permentcountry"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Country"
                          />
                          <label for="occid"
                            >Country</label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <select
                            name="gendddfddder"
                            class="form-control form-input"
                            id="gedfdnder"
                            v-model="contactDetails.permentstate"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in stateList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>

                        
                          <label for="occid"
                            >State </label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.permentdistrict"
                            class="form-control form-input"
                            id="occid"
                            placeholder="City/Town"
                          />
                          <label for="occid"
                            >City/Town</label
                          >
                        </span>
                      </div>
                      <div class="form-group input-group col-md-4 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="occ"
                            v-model="contactDetails.permentpostalCode"
                            class="form-control form-input"
                            id="occid"
                            placeholder="Pin Code"
                          />
                          <label for="occid"
                            >Pin Code</label
                          >
                        </span>
                      </div>
                    
                     
                    
                  </div>
                </div>
                  <div v-if="editId">
                 
                  <label
                    style="margin: 0"
                    for="accordiongroup-2"
                    class="blutitle accordion-label"
                  >
                    OTHER INFORMATION
                  </label>
                  <div class="form-row">
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    name="Blood Group"
                    class="form-control form-input"
                    id="bloodGroup"
                    v-model="contactDetails.bloodGroup"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(blood, index) in bloodGroupList"
                      :key="index"
                      :value="blood"
                    >
                      {{ blood }}
                    </option>
                  </select>
                 
                  <label for="bloodGroup"
                    >Blood Group </label
                  >
                </span>
              </div>
             

                  <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <select
                            name="gender"
                            class="form-control form-input"
                            id="gender"
                            v-model="contactDetails.gender"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in genderList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                         
                          <label for="firstName" 
                            >Gender </label
                          >
                        </span>
                      </div>
                     <!-- <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                            v-model="userDetails.religion"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Religion"
                          />
                          <label for="relid"
                            >Religion </label
                          >
                        </span>
                        
                      </div> -->

                      <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="nationality"
                            v-model="userDetails.nationality"
                            class="form-control form-input"
                            id="natid"
                            placeholder="Nationality"
                          />
                          <label for="natid"
                            >Nationality </label
                          >
                        </span>
                      </div>
                     <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                           <select
                            name="gender"
                            class="form-control form-input"
                            id="gender"
                            v-model="contactDetails.category"
                          >
                            <option :value="null">-- Select --</option>
                            <option
                              v-for="(item, index) in categoryList"
                              :key="index"
                              :value="item"
                            >
                              {{ item }}
                            </option>
                          </select>
                         
                         
                          <!-- <input
                            type="text"
                            name="Religion"
                            v-model="contactDetails.category"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Religion"
                          /> -->
                          <label for="relid"
                            >Category </label
                          >
                        </span>
                        
                      </div>
                      <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                            v-model="contactDetails.previousSchool"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Religion"
                          />
                          <label for="relid"
                            >Previous School  </label
                          >
                        </span>
                        
                      </div>
                      <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                        <span class="has-float-label">
                          <input
                            type="text"
                            name="Religion"
                            v-model="contactDetails.previousClassPassed"
                            class="form-control form-input"
                            id="relid"
                            placeholder="Previous Class Passed"
                          />
                          <label for="relid"
                            >Previous Class Passed </label
                          >
                        </span>
                        
                      </div>
                       <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <div style="border-bottom: 1px solid #e5e5e5; width: 100%">
                  <label
                    style="color: #6c757d; margin-bottom: 0.2rem"
                    class="mr-3"
                    >RTE Quota</label
                  >
                  <label class="switch" style="margin-bottom: 0rem">
                    <input
                      type="checkbox"
                      id="sibtogBtn"
                      v-model="contactDetails.isrtequota"
                    />
                    <div class="slider"></div>
                  </label>
                </div>
              </div>
               <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <div style="border-bottom: 1px solid #e5e5e5; width: 100%">
                  <label
                    style="color: #6c757d; margin-bottom: 0.2rem"
                    class="mr-3"
                    >Disability</label
                  >
                  <label class="switch" style="margin-bottom: 0rem">
                    <input
                      type="checkbox"
                      id="sibtogBtn"
                      v-model="contactDetails.isDisability"
                    />
                    <div class="slider"></div>
                  </label>
                </div>
              </div>
                    
                  </div>
                </div>
              </fieldset>
            </div>
            
          </form>
        </div>
        <div
              class=" mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <!-- <div class="dk_icon">
               
              </div> -->
              <div class="text-center dk_iconsml">
                
                <button v-if="editId" 
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="updateStudent"
                >
                    <span>Update</span>
                </button>
                 <button v-else
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="createStudent"
                >
                    <span>Save</span>
                </button>
                <button type="button" @click.prevent="closePoup" class="btn btncl clsmdl" id="clbtn">
                     <span>Cancel</span>
                </button>
              </div>
            </div>
      </div>
    </b-modal>
    <b-modal id="deleteStudentPopup" no-close-on-backdrop no-close-on-esc>
      <button type="button" class="close"  @click.prevent="closeDeletePopUp">×</button>
        <div class="contact-form">
          <h2>Delete Confirmation</h2>
          <br />
          Are you sure you want to delete this record?
           <div
                class="pt-3 text-center dk_iconsml"
                style="border-top: 1px solid #e5e5e5"
              >
                <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="confirmDelete"
                >
                    <span>Confirm</span>
                </button>
                <button @click.prevent="closeDeletePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
                     <span>Cancel</span>
                </button>
              </div>
        </div>
    </b-modal>
    
    <b-modal id="resetMailPopup" style="width: 40%" size="sm" no-close-on-backdrop
      no-close-on-esc >
      <button type="button" class="close"  @click.prevent="closePrimiaryMailChange">×</button>
      <div class="contact-form" v-if="isVerifyCode == false">
        <h2>Change Primary Mail</h2>
        <br />
         <div class="form-row">
        <div class="form-group input-group col-md-6 mt-3">
                <span class="web-has-float-label">
                        <input
                          @input="checkNewPrimaryEmail" 
                          class="form-control form-input"
                          v-model="newPrimaryEmail"
                        />
                        <label
                            v-if="invalidNewMail"
                            class="vi-error"
                            for="emailid"
                            style="top:-1em"
                            >Please enter valid mail format</label
                        >
                      
                       
                        <label for="passwordid" v-else
                          >New Primary Mail<span class="required">*</span></label
                        >
                      </span>
              </div>
         </div>
        <div
          class="pt-3 text-right dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button  :disabled="!newPrimaryEmail"
            type="button"
            @click.prevent="primaryMailVerify"
            class="btn btnsml"
            id="svbtn"
          >
              <span>Confirm</span>
          </button>
          <button  type="button" class="btn btncl clsmdl" @click.prevent="closePrimiaryMailChange" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
       <div class="contact-form" v-else>
        <h4>Enter 4 digit code sent to your registered email id</h4>
        <br />
         <div class="form-row">
        <div class="form-group input-group col-md-6 mt-3">
                <span class="web-has-float-label">
                       <input
                          type="number"
                          onKeyPress="if(this.value.length==4) return false;"
                          name="verifyCode"
                           class="form-control web-form-input"
                          id="verifyCodeid"
                          v-model="userVerifyCode"
                          min="4"
                        />
                       
                        <label for="verifyCodeid" 
                          >4-digit code <span class="required">*</span></label
                        >
                      </span>
              </div>
         </div>
        <div
          class="pt-3 text-right dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button  :disabled="!userVerifyCode"
            type="button"
            @click.prevent="verifyPrimaryMailCode"
            class="btn btnsml"
            id="svbtn"
          >
              <span>Verify</span>
          </button>
          <button @click.prevent="closePrimiaryMailChange" type="button" class="btn btncl clsmdl" id="clbtn">
               <span>Cancel</span>
          </button>
        </div>
      </div>
    </b-modal>
    </div>
    <vi-spinner
      v-if="viLoader"
      text="Loading..."
      textColor="vi-brand-color"
      class="flex-fill h-100 vi-fs12"
      style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
    />
  </div>
</template>

<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";
import Select from "vue-select";

export default {
  name: "Class",
  components: {
    ViSpinner,
    "v-select": Select,
  },
  data() {
    return {
      contactDetails: {

        middleName : "",
        fatherFirstName : "",
        fatherLastName : "",
        fatherOccuption : "",
        motherFirstName : "",
        motherLastName : "",
        motherOccuption : "",
        guardianFirstName : "",
        guardianLastName : "",
        guardianOccuption : "",
        mobileThree: null,
        isPermentAddress: false,
        isrtequota: false,
        isDisability: false,
        permentaddressOne  : "",
        permentaddressTwo : "",
        permentstate : "",
        permentdistrict : "",
        permentpostalCode : "",
        permentcountry : "",
        category : "",
        previousSchool : "",
        previousClassPassed : "",
        siblingUserId: "",
        siblingClass: "",
        siblingSection: "",
        firstName : "",
        lastName: "",
        admissionType: "online",
        dob: null,
        doj: null,
        branchId: null,
        dateOfLeaving: null,
        pointOfContact: "",
        academicYear: null,
        gender: "",
        parentId: null,
        accountId: null,
        email: "",
        mobileOne: null,
        mobileTwo: null,
        image: {},
        addressOne: "",
        addressTwo: "",
        state: "",
        district: "",
        postalCode: "",
        country: "",
        classApplied: "",
        bloodGroup: null,
        admissionStatus: 'Active',
        siblings: false,
        isStudentDiscount: false,
        studentDiscountValue: "",
        rollNoAllocated: "",
        class: null,
        classSection: null,
        siblingName: null,
        siblingRollNo: "",
        siblingDiscount: ''
        
      },

      userDetails: {
        firstName: "",
        lastName: "",
        fatherName: "",
        motherName: "",
        email: "",
        phoneNo: null,
        password: "",
        role: "",
        membership: "Free",
        userId: "",
        gender: "",
        occupation: "",
        religion: "",
        nationality: "",
        address: "",
        registeredAs: "",
        userDevices: [],
        profile: null,
        userName: ''
      },

      enrollmentStatusList: [
        "Active",
        "Inactive",
        "Submitted", 
        "In Review",
        "Assessment",
        "Approved", 
        "Reject", 
        "Enrolled"
      ],
      pointOfList: [
       'Father',
       'Mother',
       'Guardian'
      ],
      genderList: [
       'Male',
       'Female'
      ],
      usernameConcat: '',
      showOption: false,
      isVerifyCode : false,
      generatedVerifyCode: null,
      userVerifyCode: null,
      newPrimaryEmail: '',
      invalidNewMail: false,
      isNewPrimaryMailValid: true,
      allowEditAcademicYear: true,
      viLoader: false,
      searchWords: '',
      skip: 0, // set the skip info
      limit: 7, // set the limit info
      currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      countAll: true,
      totalCount: [],
      selectClass : null,
      deleteId: null,
      deleteParentId: null,
      editId: null,
      feesNotPaid: false,
      userId: null,
      minPlanDate: null,
      maxPlanDate: null,
      classList: [],
      prevPhno: '',
      prevUserName: '',
      prevROllNo: '',
      schoolNameAppendValue: '',
      isMobileAvailable: true,
      isEmailIdAvailable: true,
      isRollNoAvailable: true,
      minDob: null,
      sectionList: [],
      studentList: [],
      academicYearList: [],
      allStudentList: [],
      parentMailVerifiyed: false,
      selectAcademicYear: null,
      selectedStatus: null,
      selectedSibiling: false,
      allSectionList: [],
      statusList: [ "Active","Inactive"],
      bloodGroupList: ["A+", "O+", "B+", "AB+", "A-", "O-", "B-", "AB-"],
      categoryList: ["General/OC","BC","SC","MBC","ST","Minority"],
      stateList: [
         "Andaman and Nicobar Islands",
        "Andhra Pradesh",
         "Arunachal Pradesh",
         "Assam",
         "Bihar",
         "Chandigarh",
         "Chhattisgarh",
         "Dadra and Nagar Haveli",
         "Daman and Diu",
         "Delhi",
         "Goa",
         "Gujarat",
         "Haryana",
         "Himachal Pradesh",
         "Jammu and Kashmir",
         "Jharkhand",
         "Karnataka",
         "Kerala",
         "Lakshadweep",
         "Madhya Pradesh",
         "Maharashtra",
         "Manipur",
         "Meghalaya",
         "Mizoram",
         "Nagaland",
         "Odisha",
         "Puducherry",
         "Punjab",
         "Rajasthan",
         "Sikkim",
        "Tamil Nadu",
        "Telangana",
         "Tripura",
         "Uttar Pradesh",
         "Uttarakhand",
         "West Bengal",
      ]
    };
  },
  created() {
    this.getAcademicYearList();
    this.getClassList();
    this.getStudentList();
    this.setDob()
    this.getAllStudentList(true)
    this.getAllSectionList()
  },
  computed: {
    styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 95) + 'px'
       };
      },
      optionStaffList() {
      return this.auvaiableStudentList.map((x) => {
        return {
          // label: x.firstName + " " + x.lastName + " / " + x.userId,
          label: x.firstName + " " + x.lastName,
          value: x._id,
        };
      });
    },
      getUserName(){
        let userData = secureUI.sessionGet("user");
        return userData.userDetails.firstName + " " + userData.userDetails.lastName
      },
      auvaiableStudentList(){
        return this.allStudentList.filter( x => x._id != this.editId)
      },
      getActiveStudents(){
      return this.totalCount.filter( x => x.admissionStatus == 'Active').length
      },
      getInActiveStudents(){
      return this.totalCount.filter( x => x.admissionStatus == 'Inactive').length
      },
      getBranchList(){
      return this.$store.getters.getBranchList
      },
     
  },
  watch: {
          searchWords: function (currentVal, oldVal) {
               let hasCurrentParam = currentVal || '';
               let hasOldParam = oldVal || '';

              if (hasCurrentParam != hasOldParam) {
                   this.skip = 0
                   this.currentPageNumber = 1,
                   this.lastPageNumber = 1,
                   this.selected = 1,
                   this.totalCount = [],
				           this.countAll = true,
                  this.getStudentList();
              }
          }
  },
  methods: {
    previousRoute(){
    //  let data = localStorage.getItem('previousTab');
    //  localStorage.setItem("activeTab",data);
    //  localStorage.setItem("previousTab",data);
    //  this.$router.go(-1)
        localStorage.setItem("activeTab", '/admission/home');
        localStorage.setItem("previousTab", '/admission/home');
        this.$router.push({ path: '/admission/home' });
    },
    getAcademicYearName(id){

	  const acdemicYearDetails =  this.academicYearList.find((x) => x._id == id);
      if(acdemicYearDetails){
       return acdemicYearDetails.from +  ' - '  + acdemicYearDetails.to
      }else{
       return ''  
      }
     
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    setPlanDate(acdyear){
      if(acdyear){
        const selectAcdYear = this.academicYearList.find( x => x._id == acdyear);
        
        let start = new Date(selectAcdYear.from)
        let end = new Date(selectAcdYear.to)
        let startmonth = start.getMonth()+1
        let startdate = start.getDate()
        

        if (startdate < 10) {
          startdate = '0' + startdate;
        }
        if (startmonth < 10) {
          startmonth = '0' + startmonth;
        }
        

        this.minPlanDate =  start.getFullYear() + "-" + startmonth + "-" + startdate;
        let endmonth = end.getMonth()+1

        let enddate = new Date(end.getFullYear(), endmonth, 0).getDate();


        if (endmonth < 10) {
          endmonth = '0' + endmonth;
        }
        
        this.maxPlanDate =  end.getFullYear() + "-" + endmonth + "-" + enddate;

      }else{
        this.contactDetails.doj = null
      }
       this.contactDetails.class = null;
       this.contactDetails.classSection = "";
       this.sectionList = []
    },
    setDob(){

      let start = new Date()

      let startmonth = start.getMonth() + 1
      let startdate = start.getDate()
      let startYear = start.getFullYear()

      if (startdate < 10) {
        startdate = '0' + startdate;
      }
      if (startmonth < 10) {
        startmonth = '0' + startmonth;
      }
      this.minDob = startYear + "-" + startmonth + "-" + startdate;
     
    },
    resetMailId(){
      this.$bvModal.show("resetMailPopup");
    },
    showdrop() {
        var showdp = document.getElementById("showdropmenu");
        if (showdp.style.display === "block") {
          showdp.style.display = "none";
        } else {
          showdp.style.display = "block";
        }
        setTimeout(function () {
              showdp.style.display='none';
          }, 10000);
          return false;
    },
    async navToSelectedPage(selectedPageNo) {

      this.skip = (this.limit * (selectedPageNo - 1));

      this.countAll = false

      this.getStudentList();
    },

    async goToNextPage() {
      this.countAll = false
      this.currentPageNumber = this.currentPageNumber + 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));

      this.getStudentList();
    },

    async goToPreviousPage() {
       this.countAll = false
      this.currentPageNumber = this.currentPageNumber - 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));
     
      this.getStudentList();
    },
    
    async checkEmail() {

      let allowDupUser = true;

      if (this.editId) {
          if ( this.userDetails.userName == this.prevUserName) {
            allowDupUser = false;
          } 
      }

      if (allowDupUser) {

      this.userDetails.userName = this.userDetails.userName.trim()

      this.userDetails.userName = this.userDetails.userName.toLowerCase();

      let tempUsername = this.userDetails.userName + this.schoolNameAppendValue
      
      const response = await ViService.viGetOnly(`/common/checkApiName?cName=User&userName=${tempUsername}`);
      
      if (response.isSuccess) {
        this.isEmailIdAvailable = response.isApiNameAvailable;
      } else {
        this.$toasted.error(response.message);
         if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
      }
     } 
    },
    async checkNewPrimaryEmail() {

      var mailValid = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i;
      let allow = false

      if(!mailValid.test(this.newPrimaryEmail)){
        this.invalidNewMail = true,
        this.isNewPrimaryMailValid = true
        allow = false
      }else{
        this.invalidNewMail = false,
        allow = true
      }

    
    },
    async primaryMailVerify() {
       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
      if(this.isNewPrimaryMailValid && !this.invalidNewMail){
        ViService.viXPost("/user/change/mail/mailVerification/code/sent", { email: this.newPrimaryEmail },userData.token)
          .then((res) => {
            
            if (res.isSuccess) {
              this.isVerifyCode = true
              this.generatedVerifyCode = res.data
              this.$toasted.success(res.message);
            } else {
              this.$toasted.error(res.message);
              if(res.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
              }
            }
          })
          .catch((e) => {
            this.$toasted.error(e);
          });

      }
    }
    },
    async checkROllNo() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {

        let allowDupUser = true;

        if (this.editId) {
          if ( this.contactDetails.rollNoAllocated == this.prevROllNo) {
            allowDupUser = false;
          } 
        }

        if (allowDupUser) {

        this.contactDetails.rollNoAllocated = this.contactDetails.rollNoAllocated.trim()

        const response = await ViService.viGetOnly(`/common/checkApiNameWithAccount?cName=Contact&rollNoAllocated=${this.contactDetails.rollNoAllocated}&accountId=${userData.userDetails.account._id}`);
        
        if (response.isSuccess) {
          this.isRollNoAvailable = response.isApiNameAvailable
         // if(this.isRollNoAvailable){
          //this.userDetails.userName = this.contactDetails.rollNoAllocated + this.schoolNameAppendValue
           
         // }
          
        } else {
          this.$toasted.error(response.message);
        }
        } 
      }
    },
    
    closePrimiaryMailChange(){
      this.isVerifyCode  = false,
      this.generatedVerifyCode = null,
      this.userVerifyCode = null,
      this.newPrimaryEmail = '',
      this.invalidNewMail = false,
      this.isNewPrimaryMailValid = true,
      this.$bvModal.hide("resetMailPopup");
    },
    verifyPrimaryMailCode(){
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        let code = this.generatedVerifyCode.toString()

        if(code == this.userVerifyCode){
           ViService.viXPost(`/user/updatePrimarymail/${this.userId}`, { email: this.newPrimaryEmail },userData.token)
          .then((res) => {
            
            if (res.isSuccess) {
              this.closePrimiaryMailChange()
              this.closePoup();
              this.selectClass = null;
              this.getStudentList();
              this.$toasted.success(res.message);
            } else {
              this.$toasted.error(res.message);
              if(res.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
              }
            }
          })
          .catch((e) => {
            this.$toasted.error(e);
          });

        }else{
          this.$toasted.error('Invalid Code');
        }
      }
    
    },
    
    getClassName(classid){
      const classDetails =  this.classList.find((x) => x._id == classid);
      if(classDetails){
        return classDetails.className
      }else{
        return 'No Assigned Class'  
      }
      
    },
    getSectionName(id){
      const sectionDetails =  this.allSectionList.find((x) => x._id == id);
      if(sectionDetails){
        return sectionDetails.name
      }else{
        return ''
      }
    },
    setSblingRollNo(id){
   console.log("id",id)
    if(id){
      const sblingDetails =  this.allStudentList.find((x) => x._id == id);
      
      this.contactDetails.siblingRollNo = sblingDetails.rollNoAllocated
      this.contactDetails.siblingUserId = sblingDetails.parent ? sblingDetails.parent.userName : '',
      this.contactDetails.siblingClass = this.getClassName(sblingDetails.class),
      this.contactDetails.siblingSection = this.getSectionName(sblingDetails.classSection)


    }else{
      this.contactDetails.siblingRollNo = ''
      this.contactDetails.siblingUserId = "",
      this.contactDetails.siblingClass = "",
      this.contactDetails.siblingSection = ""
    }
     
    },
    openStudentPoup(){
     this.clearForm()
     this.getStudentRollNo('new');
     this.$bvModal.show("addstudpop");
    },
    getSelectRange(){
     if(this.selectAcademicYear){
		  this.getStudentList();
     }else{
        this.selectAcademicYear = null
        this.getStudentList();
      }
    
      this.skip = 0
      this.currentPageNumber = 1,
      this.lastPageNumber = 1,
      this.selected = 1,
      this.totalCount = [],
      this.countAll = true
      
    },
    getSelectStatus(){
     if(this.selectedStatus){
		  this.getStudentList();
     }else{
        this.selectedStatus = null
        this.getStudentList();
      }
    
      this.skip = 0
      this.currentPageNumber = 1,
      this.lastPageNumber = 1,
      this.selected = 1,
      this.totalCount = [],
      this.countAll = true
      
    },
    getSelectIsSibiling(){

     if(this.selectedSibiling){
		  this.getStudentList();
     }else{
        this.selectedSibiling = false
        this.getStudentList();
      }
    
      this.skip = 0
      this.currentPageNumber = 1,
      this.lastPageNumber = 1,
      this.selected = 1,
      this.totalCount = [],
      this.countAll = true
      
    },
    selectClassSetion(){
     if(this.selectClass){
		 this.getStudentList();
	  }else{
      this.selectClass = null
      this.getStudentList();
    }

    this.skip = 0
    this.currentPageNumber = 1,
    this.lastPageNumber = 1,
    this.selected = 1,
    this.totalCount = [],
    this.countAll = true
	  },
    showDeleteModal(id,parentId){
        this.deleteId = id
        this.deleteParentId = parentId,
        this.$bvModal.show("deleteStudentPopup");
    },
    closeDeletePopUp(){
        this.deleteId = null
        this.deleteParentId = null,
        this.$bvModal.hide("deleteStudentPopup");
    },
    clearForm(){

          this.contactDetails.firstName = "";
          this.contactDetails.lastName = "";
          this.contactDetails.admissionType = "online";
          this.contactDetails.dob = null;
          this.contactDetails.academicYear = null;
          this.contactDetails.gender = "";
          this.contactDetails.parentId = null;
          this.contactDetails.accountId = null;
          this.contactDetails.email = "";
          this.contactDetails.mobileOne = null;
          this.contactDetails.mobileTwo = null;
          this.contactDetails.image = {};
          this.contactDetails.addressOne = "";
          this.contactDetails.addressTwo = "";
          this.contactDetails.state = "";
          this.contactDetails.district = "";
          this.contactDetails.postalCode = "";
          this.contactDetails.country = "India";
          this.contactDetails.classApplied = "";
          this.contactDetails.bloodGroup = null;
          this.contactDetails.admissionStatus = 'Active';
          this.contactDetails.siblings = false;
          this.contactDetails.rollNoAllocated = "";
          this.contactDetails.class = null;
          this.contactDetails.classSection = "";
          this.contactDetails.siblingName = "";
          this.contactDetails.siblingRollNo = "";
          this.contactDetails.siblingDiscount = '';
          this.userDetails.firstName = "";
          this.userDetails.lastName = "";
          this.userDetails.fatherName = "";
          this.userDetails.motherName = "";
          this.userDetails.email = "";
          this.userDetails.phoneNo = null;
          this.userDetails.password = "";
          this.userDetails.role = "";
          this.userDetails.membership = "Free";
          this.userDetails.userId = "";
          this.userDetails.gender = "";
          this.userDetails.occupation = "";
          this.userDetails.religion = "";
          this.userDetails.nationality = "";
          this.userDetails.address = "";
          this.userDetails.userName = '';
          this.userDetails.registeredAs = "";
          this.userDetails.userDevices = [];
          this.userDetails.profile = null,
          this.contactDetails.fatherFirstName = "",
          this.contactDetails.fatherLastName = "",
          this.contactDetails.fatherOccuption = "",
          this.contactDetails.doj = null,
          this.contactDetails.middleName  = "",
          this.contactDetails.motherFirstName = "",
          this.contactDetails.motherLastName = "",
          this.contactDetails.motherOccuption = "",
          this.contactDetails.guardianFirstName = "",
          this.contactDetails.guardianLastName = "",
          this.contactDetails.guardianOccuption = "",
          this.contactDetails.mobileThree = null,
          this.contactDetails.isPermentAddress = false,
          this.contactDetails.isrtequota = false,
          this.contactDetails.isDisability = false,
          this.contactDetails.permentaddressOne  = "",
          this.contactDetails.permentaddressTwo = "",
          this.contactDetails.permentstate = "",
          this.contactDetails.permentdistrict = "",
          this.contactDetails.permentpostalCode = "",
          this.contactDetails.permentcountry = "",
          this.contactDetails.category = "",
          this.contactDetails.previousSchool = "",
          this.contactDetails.previousClassPassed = "",
          this.contactDetails.siblingUserId = "",
          this.contactDetails.siblingClass = "",
          this.contactDetails.siblingSection = "",
          this.userId = null,
          this.editId = null,
          this.feesNotPaid = false,
          this.prevPhno = '',
          this.prevUserName = '',
          this.prevROllNo = '',
          this.isMobileAvailable = true
          this.isEmailIdAvailable = true 
          this.isRollNoAvailable = true
        
    },
    setPermentAddress(value){

  if(value){
          this.contactDetails.permentaddressOne  = this.contactDetails.addressOne,
          this.contactDetails.permentaddressTwo = this.contactDetails.addressTwo,
          this.contactDetails.permentstate = this.contactDetails.state,
          this.contactDetails.permentdistrict = this.contactDetails.district,
          this.contactDetails.permentpostalCode = this.contactDetails.postalCode,
          this.contactDetails.permentcountry = this.contactDetails.country
  }else{
  this.contactDetails.permentaddressOne  = "",
          this.contactDetails.permentaddressTwo = "",
          this.contactDetails.permentstate = "",
          this.contactDetails.permentdistrict = "",
          this.contactDetails.permentpostalCode = "",
          this.contactDetails.permentcountry = 'India'
  }
   
    },
    closePoup(){
     
     this.$bvModal.hide("addstudpop");
     this.clearForm();
    },
    async getAllSectionList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {

        const response = await ViService.viXGet(
          "/class/getAllSectionList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);
          this.allSectionList = details.sections
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
           }
        }
      }
    },
    async getSectionList(classId){
      if(classId){ 
        this.sectionList = []
       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(`/contact/getSectionList?class=${classId}`,userData.token);
        if (response.isSuccess) {
          this.sectionList = secureUI.secureGet(response.data);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      }
    },
    async createStudent() {
       let isFormValid = false;

       await this.$validator.validateAll('studentvalidate').then(result => {
          isFormValid = result;
       });

      if (isFormValid  && this.isMobileAvailable && this.isRollNoAvailable && this.isEmailIdAvailable) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        
        this.$toasted.error("Please login and do the action");

      } else {

       // this.viLoader = true;

        const classDetails =  this.classList.find((x) => x._id == this.contactDetails.class);
         
        if(classDetails){
          this.contactDetails.className = classDetails.className
        }
        let tempUsername = this.userDetails.userName

        this.userDetails.userName = this.userDetails.userName + this.schoolNameAppendValue
        
        this.userDetails['branchId'] = this.contactDetails.branchId

        const response = await ViService.viXPost(
          "/contact/student/schooladmission",
          {
            contactDetails: this.contactDetails,
            userDetails: this.userDetails,
          },
          userData.token
        );

       // this.viLoader = null;

        if (response.isSuccess) {

          this.closePoup();
          this.selectClass = null;
          this.getStudentList();
          this.getAllStudentList(false)
          this.$toasted.success(response.message);

           let getCounts = this.$store.getters.getAdmissionObjCounts
           getCounts.student = getCounts.student + 1

           this.$store.dispatch('setAdmissionObjCOunt', getCounts);

        } else {

          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
          }
          this.userDetails.userName =  tempUsername

        }
      }
      }else{
        this.$toasted.error('Please fill all the required details');
      }
    },
    async updateStudent() {
       let isFormValid = false;

       await this.$validator.validateAll('studentvalidate').then(result => {
          isFormValid = result;
       });

      if (isFormValid && this.isMobileAvailable && this.isRollNoAvailable && this.isEmailIdAvailable) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        this.userDetails.userName = this.userDetails.userName + this.schoolNameAppendValue
        
       // this.viLoader = true;
        const response = await ViService.viXPut(
          `/contact/student/updateSchooladmission/${this.editId}/${this.userId}`,
          {
            contactDetails: this.contactDetails,
            userDetails: this.userDetails,
          },
          userData.token
        );
       
        this.viLoader = null;

        if (response.isSuccess) {
          this.closePoup();
          this.selectClass = null;
          this.getStudentList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      }else{
        this.$toasted.error('Please fill all the required details');
      }
    },
    async checkMobile() {
        let allowDupUser = true;

        if (this.editId) {
          if ( this.userDetails.phoneNo == this.prevPhno) {
            allowDupUser = false;
          } 
        }

        if (allowDupUser) {

          this.userDetails.phoneNo = this.userDetails.phoneNo.trim()
          this.userDetails.phoneNo = Number(this.userDetails.phoneNo)
          
           const response = await ViService.viGetOnly(`/common/checkApiName?cName=User&phoneNo=${this.userDetails.phoneNo}`);

          if (response.isSuccess) {
              this.isMobileAvailable = response.isApiNameAvailable
            } else {
              this.$toasted.error(response.message);
               if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
            }
            }
    },
    async editStudentModal(index){
      this.feesNotPaid = false
      this.editId = null

      const cloneStudentList = [...this.studentList ]

      const studentDetails = cloneStudentList[index];

      const cloneStudentDetails = JSON.parse(JSON.stringify(studentDetails))

      this.contactDetails = cloneStudentDetails

      if(this.contactDetails.dob){
        this.contactDetails.dob = this.contactDetails.dob.substring(0,10);
      }
      if(this.contactDetails.doj){
        this.contactDetails.doj = this.contactDetails.doj.substring(0,10);
      }
       if(this.contactDetails.dateOfLeaving){
        this.contactDetails.dateOfLeaving = this.contactDetails.dateOfLeaving.substring(0,10);
      }

      if(this.contactDetails.class == '6163b70b480d45e2a1522974'){
         this.classList.push({
         className: 'No Assigned Class',
         _id: '6163b70b480d45e2a1522974'
        })

        this.sectionList.push({
         name: 'No Assigned Section',
         _id: '6163b74c480d45e2a152378a'
        })
      }else{
       this.getSectionList(this.contactDetails.class)
      }
     
     
      this.userDetails = cloneStudentDetails.parent
      
      this.editId = cloneStudentDetails._id
      await this.getStudentFeePlanList(this.editId,this.contactDetails.academicYear)
      
      this.prevPhno = this.userDetails.phoneNo
      this.prevROllNo = this.contactDetails.rollNoAllocated
      this.userId = cloneStudentDetails.parent._id
      this.userDetails.userName = this.userDetails.userName.split("@")[0]
      this.prevUserName = this.userDetails.userName
      this.getStudentRollNo('edit')
      this.$bvModal.show("addstudpop");
    },
    async getStudentFeePlanList(studentId,studentAcdyr) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push('/login');
      } else {
       // this.studentFeePlanList = [];
        const response = await ViService.viXGet(
          `/contact/getStudentFeePlanList?studentId=${studentId}&academicYear=${studentAcdyr}`,
          userData.token
        );
        if (response.isSuccess) {
          const studentDetails = secureUI.secureGet(response.data);
       
            if(studentDetails.length > 0){
              const currentFeePlanDetails = studentDetails.filter( x => x.type == 'Term-Fees')
              if(currentFeePlanDetails.length > 0){
                const checkFeeDetails = currentFeePlanDetails[0].status
                this.allowEditAcademicYear = checkFeeDetails == 'Not Paid' || checkFeeDetails == 'Partial Paid' ? false : true
              }else{
               this.allowEditAcademicYear = true
              }
            }else{
              this.allowEditAcademicYear = true
            }
           

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async inviteParent(index){

      const studentDetails = this.studentList[index];

      const cloneStudentDetails = { ...studentDetails }
      let userData = secureUI.sessionGet("user");

      const response = await ViService.viXPost(
          "/contact/invite/parent/createPassword",
          cloneStudentDetails,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }

    },
    async getAllStudentList(value) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        if(value){
           this.viLoader = true;
        }
        const response = await ViService.viXGet(
          "/contact/getStudentList?viewType=list",
          userData.token
        );
        if (response.isSuccess) {
          this.allStudentList = secureUI.secureGet(response.data);

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
        this.viLoader = false;
      }
    },
    async getStudentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(`/contact/getStudentListView?class=${this.selectClass}&academicYear=${this.selectAcademicYear}&status=${this.selectedStatus}&sibiling=${this.selectedSibiling}&skip=${this.skip}&limit=${this.limit}&search=${this.searchWords}&isCount=${this.countAll}`,userData.token);
      
        if (response.isSuccess) {

           const resultData = secureUI.secureGet(response.data);

           this.studentList = resultData.studentList
         
           if(this.countAll){
            this.totalCount = resultData.totalCount
            this.lastPageNumber = Math.ceil(this.totalCount.length/this.limit);
           }

          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getClassList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        this.classList = []
        const response = await ViService.viXGet(
          "/contact/getClassList",
          userData.token
        );
        if (response.isSuccess) {
          this.classList = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getStudentRollNo(type) {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/contact/getRollNo",
          userData.token
        );
        if (response.isSuccess) {
          const rollNo = response.data.rollno;
          if(rollNo){
            if(type == 'new'){
              this.contactDetails.rollNoAllocated = rollNo
              this.usernameConcat = response.data.username
              this.userDetails.userName = rollNo

              
              let getDetails = this.$store.getters.getCurrentAcademicYear;
              
              if (getDetails && getDetails.from) {
                this.contactDetails.academicYear = getDetails._id;
                //return getDetails.from + "-" + getDetails.to;
              }

              let selectedBranch = localStorage.getItem("currentBranch");

              if(selectedBranch){
                this.contactDetails.branchId = selectedBranch
              }

              
            }

            this.schoolNameAppendValue = response.data.appendValue ? response.data.appendValue.toLowerCase() : ''

          }
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getAcademicYearList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/academicYear/getAcademicYearListBranch",
          userData.token
        );
        if (response.isSuccess) {
          this.academicYearList = secureUI.secureGet(response.data);

          if(this.academicYearList.length > 0 ){
          let getDetails = this.$store.getters.getCurrentAcademicYear;
              
          if (getDetails && getDetails.from) {
            // @change="getSelectRange"
            this.selectAcademicYear = getDetails._id;
          }
         }
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
     async confirmDelete() {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
      //  this.viLoader = true;
        const response = await ViService.viDelete(`/contact/student/admission/${this.deleteId}/${this.deleteParentId}`,userData.token);

		if (response.isSuccess) {
			this.$bvModal.hide("deleteStudentPopup");
			this.deleteId = null;
      this.deleteParentId = null,
      this.getStudentList();
       let getCounts = this.$store.getters.getAdmissionObjCounts
           getCounts.student = getCounts.student - 1

           this.$store.dispatch('setAdmissionObjCOunt', getCounts);
      this.$toasted.success(response.message);
    } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
    }
      }
    },
  },
};
</script>